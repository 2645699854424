import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"

import {
  Layout,
  Title,
  PrimaryButton,
  SEO,
  SectionCenter,
  Card,
  SecondaryButton,
} from "../components"
import destinLogo from "../../images/destin-logo.png"
import tugoLogo from "../../images/tugo-logo.png"
import gmsLogo from "../../images/gms-logo.png"
import { mq } from "../utils/media-query"
import { getCurrentPath } from "../redux/actions/pathAction"
import insurerClaimProcessCards from "../utils/insurerClaimProcessCards"
import claimWording from "../utils/claimSteps"

const initShow = {
  gms: false,
  tugo: false,
  tugoStudent: false,
  destination: false,
}

const logos = {
  gms: gmsLogo,
  tugo: tugoLogo,
  tugoStudent: tugoLogo,
  destination: destinLogo,
}

const Claims = ({ location, getCurrentPath }) => {
  const intl = useIntl()

  const [show, setShow] = useState(initShow)

  const checkShowProcess = show => {
    return Object.keys(show).filter(ins => show[ins]).length > 0 ? true : false
  }

  const toggleShowProcess = insurer => {
    setShow(Object.assign({}, initShow, { [insurer]: !show[insurer] }))
  }

  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  return (
    <Layout>
      <SEO title="Claim process" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <SectionCenter>
          <Title
            title={intl.formatMessage({ id: "claim.title" })}
            fontColor="var(--clr-primary-300)"
          />
          {!checkShowProcess(show) && (
            <div
              css={css`
                max-width: 1000px;
                margin: 0 auto 1rem;
                display: flex;
                text-align: center;
                flex-wrap: wrap;

                @media (min-width: 860px) {
                  width: 100%;
                  justify-items: center;
                  justify-content: center;
                  flex-wrap: nowrap;
                }
                /* 992px */
                ${mq[1]} {
                  margin: 1rem auto 1rem;
                  width: 100%;
                  justify-items: center;
                  justify-content: center;
                  flex-wrap: nowrap;
                }
              `}
            >
              {insurerClaimProcessCards.map(cardItem => (
                <React.Fragment key={cardItem.label}>
                  <div
                    css={css`
                      width: 100%;
                    `}
                  >
                    <Card
                      {...cardItem}
                      onClick={() => toggleShowProcess(cardItem.label)}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}

          {Object.keys(show)
            .filter(ins => show[ins])
            .map(ins => (
              <Process insurer={ins} id={ins} key={ins} />
            ))}

          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            {checkShowProcess(show) && (
              <SecondaryButton
                textSize="0.875rem"
                textTransform="capitalize"
                maxWidth="none"
                margin="3rem 2rem 0 0"
                onClick={() => {
                  setShow(initShow)
                }}
              >
                {intl.formatMessage({ id: "btn.close" })}
              </SecondaryButton>
            )}
            <PrimaryButton
              textSize="0.875rem"
              textTransform="capitalize"
              maxWidth="none"
              margin="3rem 0 0 0"
              onClick={() => {
                navigate("/")
              }}
            >
              {intl.formatMessage({ id: "btn.return" })}
            </PrimaryButton>
          </div>
        </SectionCenter>
      </section>
    </Layout>
  )
}

const Process = ({ insurer }) => {
  useEffect(() => {
    const scrollContainer = document.querySelector("#scroll-container")
    const handleWheel = evt => {
      evt.preventDefault()
      scrollContainer.scrollLeft += evt.deltaY
    }

    scrollContainer.addEventListener("wheel", handleWheel)
    return () => {
      scrollContainer.removeEventListener("wheel", handleWheel)
    }
  }, [])
  return (
    <div
      css={css`
        width: 100%;
        margin: 0 auto;
      `}
    >
      <img
        src={logos[insurer]}
        alt={`${insurer}-logo`}
        css={css`
          margin: 0 auto;
          display: block;
        `}
      />
      <div
        id="scroll-container"
        css={css`
          display: grid;
          grid-template-columns: ${claimWording[insurer].reduce(
            (acc, item) => acc + "1fr ",
            ""
          )};
          column-gap: 1.5rem;
          height: 700px;
          margin-top: 3rem;
          padding: 1rem;
          overflow-x: hidden;
          overflow-y: hidden;
          justify-items: center;
          align-content: center;
          align-items: center;
        `}
      >
        {claimWording[insurer].map((item, index) => (
          <StepCard
            index={index}
            {...item}
            key={index}
            last={index === claimWording[insurer].length - 1}
          />
        ))}
      </div>
    </div>
  )
}

const StepCard = ({ index, cap, content, contact, notes, last }) => {
  const intl = useIntl()

  return (
    <div
      css={css`
        box-shadow: var(--dark-shadow-sb);
        border-radius: var(--border-radius-md);
        width: 400px;
        height: 650px;
        padding: 2rem;
        margin-left: ${index === 0 ? "2rem" : "none"};
        position: relative;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 50%;
          top: 15%;
          font-size: 30rem;
          font-family: var(--ff-primary);
          color: rgba(90, 153, 62, 0.1);
          z-index: 0;
        `}
      >
        {index + 1}
      </div>
      <div
        css={css`
          text-align: center;
          font-size: 1.5rem;
          font-weight: bold;
          font-family: var(--ff-quaternary);
          letter-spacing: var(--letter-spacing-sm);
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          line-height: 1.5;
        `}
      >
        {intl.formatMessage({ id: `claim.step${index + 1}` })}
      </div>
      {cap && (
        <div
          css={css`
            font-size: 1rem;
            font-weight: bold;
            font-family: var(--ff-quaternary);
            letter-spacing: var(--letter-spacing-sm);
            margin-bottom: 1rem;
            line-height: 1.5;
          `}
        >
          {intl.formatMessage({ id: cap })}
        </div>
      )}
      <div
        css={css`
          font-size: 1rem;
          font-family: var(--ff-quaternary);
          letter-spacing: var(--letter-spacing-sm);
          margin-bottom: 1rem;
          line-height: 1.5;
        `}
      >
        {intl.formatMessage({ id: content })}
      </div>
      {contact && (
        <div
          css={css`
            text-align: center;
            font-size: 1rem;
            font-family: var(--ff-quaternary);
            letter-spacing: var(--letter-spacing-sm);
            margin-bottom: 1rem;
            overflow-wrap: break-word;
          `}
        >
          {intl
            .formatMessage({ id: contact })
            .split("<br>")
            .map((p, index) => (
              <p
                key={index}
                css={css`
                  margin: 0;
                `}
              >
                {p}
              </p>
            ))}
        </div>
      )}
      {notes && (
        <div
          css={css`
            font-size: 1rem;
            font-family: var(--ff-tertiary);
            letter-spacing: var(--letter-spacing-sm);
            margin-bottom: 1rem;
            line-height: 1.5;
            overflow-wrap: break-word;
          `}
        >
          {intl
            .formatMessage({ id: notes })
            .split("<br>")
            .map((p, index) => (
              <p
                key={index}
                css={css`
                  font-size: 0.9rem;
                  margin: 0.25rem;
                `}
              >
                {p}
              </p>
            ))}
        </div>
      )}
    </div>
  )
}

export default connect(null, { getCurrentPath })(Claims)
