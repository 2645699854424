export default {
  gms: [
    {
      content: "gms.step1.content",
      contact: "gms.step1.contact",
      notes: "gms.step1.notes",
    },
    {
      content: "gms.step2.content",
      contact: "gms.step2.contact",
      notes: "gms.step2.notes",
    },
    {
      content: "gms.step3.content",
      contact: "gms.step3.contact",
      notes: "gms.step3.notes",
    },
  ],
  tugo: [
    {
      cap: "tugo.step1.cap",
      content: "tugo.step1.content",
      contact: "tugo.step1.contact",
    },
    {
      cap: "tugo.step2.cap",
      content: "tugo.step2.content",
      contact: "tugo.step2.contact",
    },
    {
      cap: "tugo.step3.cap",
      content: "tugo.step3.content",
      contact: "tugo.step3.contact",
      notes: "tugo.step3.notes",
    },
  ],
  tugoStudent: [
    {
      cap: "tugo.step1.cap",
      content: "tugo.step1.content",
      contact: "tugo.step1.contact",
    },
    {
      cap: "tugo.step2.cap",
      content: "tugo.step2.content",
      contact: "tugo.step2.contact",
    },
    {
      cap: "tugo.step3.cap",
      content: "tugo.step3.content",
      contact: "tugo.step3.contact",
      notes: "tugo.step3.notes",
    },
  ],
  destination: [
    {
      content: "destination.step1.content",
      contact: "destination.step1.contact",
      notes: "destination.step1.notes",
    },
    {
      content: "destination.step2.content",
      notes: "destination.step2.notes",
    },
    {
      content: "destination.step3.content",
    },
    {
      content: "destination.step4.content",
      notes: "destination.step4.notes",
    },
    {
      content: "destination.step5.content",
      contact: "destination.step5.contact",
      notes: "destination.step5.notes",
    },
    {
      content: "destination.step6.content",
    },
    {
      content: "destination.step7.content",
      contact: "destination.step7.contact",
      notes: "destination.step7.notes",
    },
  ],
}
